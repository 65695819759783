/* eslint-disable */
import spborder from "./api/SpbOrderApi";
import spbadmin from "./api/SpbAdminApi"
import spbKkt from './api/SpbKktApi';
class POFulfillment {
	async getDetail(keyId) {
		const response = await spborder.get(
			`/spb/po/detail/${keyId}`
		);
		return response.data[0];
	}

	async getItems(headerId, dbInstance) {
		const response = await spborder.get(
			`/spb/po/detail/items/${headerId}/${dbInstance}`
		);
		return response.data;
	}

	async getFileInfo(headerId) {
		const response = await spborder.get(
			`/spb/po/detail/attachments/${headerId}`
		);
		return response.data;
	}

	async submitDataSupp(payloadkirim) {
		return spborder.post('/spb/po/submitdatasupp', payloadkirim)
	}

	async getCompanySupp(payload) {
		return spbadmin.get(
			'/spb/usermapping/getusermappingsbyiduser/' + payload
		);
	}
	getRecipientMail(data) {
		return spbadmin.post(
			'/spb/notification/getPlannoRecipientMailPur',
			data
		);
	}
	async sendPONotifSupp(payload) {
		const url = `spb/notification/sendPOnotifsupp`;
		return spbadmin.post(url, payload);
	}
	async sendPOEmail(data) {
    	return spbKkt.post("adm/send/emailPOFulfillToPurchasing", data);
	}
	submitApi(payload) {
		return spborder.post('/spb/spb_pofulfillment/submitapi', payload);
	}
}

export default new POFulfillment();