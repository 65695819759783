<template>
	<div class="grid">
		<!--  Notification  -->
		<ProgressModal :progress="progress">
			<template #message>
				Confirm to Purchasing in progress.<br />Please wait...
			</template>
		</ProgressModal>
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		<ConfirmDialog group="positionDialog"></ConfirmDialog>
		<!--  Breadcrumb  -->
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>

		<div class="col-12 p-fluid">
			<div class="card">
				<div class="p-fluid grid formgrid" style="margin-top: 0.5em">
					<div class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								v-model="poNumber"
								disabled
								type="text"
							/>
							<label for="inputtext">Po Number</label>
						</span>
					</div>
					<div class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								v-model="revision"
								disabled
								type="text"
							/>
							<label for="inputtext">Revision</label>
						</span>
					</div>
					<div class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								v-model="buyer"
								disabled
								type="text"
							/>
							<label for="inputtext">Buyer</label>
						</span>
					</div>
					<div class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								v-model="statusHeader"
								disabled
								type="text"
							/>
							<label for="inputtext">Status</label>
						</span>
					</div>
					<div v-if="!displayConfirm" class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								v-model="confirmBy"
								disabled
								type="text"
							/>
							<label for="inputtext">Confirm By</label>
						</span>
					</div>
					<div class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								v-model="shipTo"
								disabled
								type="text"
							/>
							<label for="inputtext">Ship To</label>
						</span>
					</div>
					<div v-if="!displayConfirm" class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<Calendar
								:minDate="newDate"
								id="dateformat"
								v-model="assignedDate"
								dateFormat="dd M yy"
								:manualInput="false"
							/>
							<label for="inputtext">Confirm Date</label>
						</span>
					</div>
				</div>
				<div
					class="card"
					style="
						background-color: #ffecec;
						height: 2em;
						margin: 0 0 2em 0;
					"
				>
					<h5 style="color: #da3831; margin-top: -10px">Terms</h5>
				</div>
				<div class="p-fluid grid formgrid">
					<div class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								v-model="top"
								disabled
								type="text"
							/>
							<label for="inputtext">ToP</label>
						</span>
					</div>
					<div class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								v-model="incoterm"
								disabled
								type="text"
							/>
							<label for="inputtext">Incoterm</label>
						</span>
					</div>
					<div class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								v-model="freight"
								disabled
								type="text"
							/>
							<label for="inputtext">Freight</label>
						</span>
					</div>
				</div>
				<div
					class="card"
					style="
						background-color: #ffecec;
						height: 2em;
						margin: 0 0 2em 0;
					"
				>
					<h5 style="color: #da3831; margin-top: -10px">
						Attachment Document
					</h5>
				</div>

				<!-- Download File button -->
				<!-- <div class="p-fileupload" v-if="attachmentFile">
					<div class="p-fileupload-buttonbar">
						<a
							:href="attachmentFile.po_file"
							class="p-button"
							_target="blank"
						>
							<i class="pi pi-download"></i>
							<span class="ml-2">{{
								attachmentFile.filename
							}}</span>
						</a>
					</div>
				</div> -->

				<div class="p-fileupload">
					<div class="fileupload-buttonbar">
						<table>
							<tr v-if="attachmentFilePur && !filePurIsEmpty">
								<td>
									<p>PO File</p>
								</td>
								<td>
									<p>:</p>
								</td>
								<td>
									<span
										class="inline-block mx-2 font-bold"
										v-if="attachmentFilePur"
										>{{ attachmentFilePur.FILENAME }}</span
									>
								</td>

								<td>
									<p>
										<a
											:href="this.linkFilePur"
											class="p-button-success"
											_target="blank"
										>
											<i class="pi pi-download"></i
											><span class="ml-2">Download</span>
										</a>
									</p>
								</td>
							</tr>
							<tr v-if="attachmentFileSupp && !fileSuppIsEmpty">
								<td>
									<p>Confirm PO File</p>
								</td>
								<td>
									<p>:</p>
								</td>
								<td>
									<p>
										<span
											class="inline-block mx-2 font-bold"
											>{{
												attachmentFileSupp.FILENAME
											}}</span
										>
									</p>
								</td>
								<td>
									<p>
										<a
											:href="this.linkFileSupp"
											class="p-button-success"
											_target="blank"
										>
											<i class="pi pi-download"></i
											><span class="ml-2">Download</span>
										</a>
									</p>
								</td>
								<td v-if="displayConfirm">
									<p>
										<Button
											label="Remove"
											class="p-button-danger"
											@click="
												onRemoveSupp(
													attachmentFileSupp.PO_HEADER_ID
												)
											"
										>
											<i class="pi pi-times"></i>
											<span class="ml-2">Remove</span>
										</Button>
									</p>
								</td>
							</tr>
						</table>
					</div>
					<div class="p-fileupload-buttonbar"></div>
				</div>

				<!-- <InputText id="inputtext" v-model="top" disabled type="text"/>
                    <label for="inputtext">ToP</label>
                </span>
          </div>
          <div class="col-12 mb-4 lg:col-6 lg:mb-5">
                <span class="p-float-label">
                    <InputText id="inputtext" v-model="incoterm" disabled type="text"/>
                    <label for="inputtext">Incoterm</label>
                </span>
          </div>
          <div class="col-12 mb-4 lg:col-6 lg:mb-5">
                <span class="p-float-label">
                    <InputText id="inputtext" v-model="freight" disabled type="text"/>
                    <label for="inputtext">Freight</label>
                </span>
          </div>
        </div>
        <div class="card" style="background-color: #ffecec; height: 2em; margin: 0 0 2em 0;">
          <h5 style="color: #da3831; margin-top:-10px"> Attachment Document </h5>
        </div> -->
				<div>
					<div v-if="displayRadioButton">
						<label class="field-radiobutton"
							>Confirmation Option</label
						>
					</div>
					<div v-if="displayRadioButton">
						<table>
							<tr>
								<td>
									<div class="field-radiobutton">
										<RadioButton
											name="option"
											value="manual"
											v-model="confOption"
											@click="changeDisplay(true)"
										/>
										<label for="manual">Manual</label>
									</div>
								</td>
								<td>
									<div class="field-radiobutton">
										<RadioButton
											name="option"
											value="digital"
											v-model="confOption"
											@click="changeDisplay(false)"
										/>
										<label for="digital">Digital</label>
									</div>
								</td>
							</tr>
						</table>
					</div>
					<div v-if="displayConfirm && confOption === 'digital'" class="col-12 mb-4 lg:col-4 lg:mb-5 mt-4">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								v-model="confirmBy"
								type="text"
							/>
							<label for="inputtext">Confirm By</label>
						</span>
					</div>
	
					<!-- <FileUpload
						id="uploadFile"
						ref="inputFile"
						mode="basic"
						chooseLabel="Choose File"
						uploadLabel="Upload File"
						fileLimit="1"
						@select="onSelect"
						:customUpload="true"
						:showUploadButton="false"
						:showCancelButton="false"
						v-if="displayUploadButton"
						@change="previewFiles"
					>
						<template #empty>No file selected.</template>
					</FileUpload> -->
					<!-- :maxFileSize="1000000" -->
					<FileUpload
						id="uploadFile"
						ref="inputFile"
						chooseLabel="Choose File"
						uploadLabel="Upload File"
						fileLimit="1"
						@select="onSelect"
						:customUpload="true"
						:showUploadButton="false"
						:showCancelButton="false"
						:maxFileSize="5000000"
						v-if="displayUploadButton"
						@change="previewFiles"
						accept=".pdf"
						v-tooltip.left="'Max file 5MB'"
					>
						<template #empty>
							<p>No file selected. (Max file 5MB)</p>
						</template>
					</FileUpload>

					<div v-if="displayConfirm" class="col-12 mb-4 mt-4 lg:col-4 lg:mb-5">
						<span class="p-float-label">
							<Calendar
								:minDate="newDate"
								id="dateformat"
								v-model="confirmDate"
								dateFormat="dd M yy"
								:manualInput="false"
							/>
							<label for="inputtext">Confirm Date</label>
						</span>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 p-fluid">
			<div class="card">
				<div
					class="card"
					style="
						background-color: #ffecec;
						height: 2em;
						margin: 0 0 2em 0;
					"
				>
					<h5
						style="
							color: #da3831;
							margin-top: -10px;
							margin-bottom: 25px;
						"
					>
						Items
					</h5>
				</div>
				<div class="col-12 mb-5">
					<DataTable
						v-model:filters="filters"
						v-model:selection="selectedPo"
						:loading="loading"
						:paginator="true"
						:rows="5"
						:rowsPerPageOptions="[5, 10, 25]"
						:value="items"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
						dataKey="pr_line_num"
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
						removableSort
						responsiveLayout="scroll"
						:scrollable="true"
						scrollHeight="600px"
						scrollDirection="both"
					>
						<!--            <Column selectionMode="multiple" headerStyle="width: 3em"></Column>-->
						<template #empty> No Data Found </template>
						<template #loading> Loading... </template>
						<Column header="Line" style="width: 9%" frozen>
							<template #body="slotProps">
								{{ slotProps.index + 1 }}
							</template>
						</Column>
						<Column
							field="item_number"
							header="Item Number"
							style="width: 15%"
						></Column>
						<Column
							field="item_description"
							header="Item Description"
							style="width: 20%"
						></Column>
						<Column
							field="uom"
							header="UOM"
							style="width: 9%"
						></Column>
						<Column header="Unit Price" style="width: 9%">
							<template #body="{ data }">
								{{ formatCurrency(data.unit_price) }}
							</template>
						</Column>
						<Column
							field="quantity"
							header="Quantity"
							style="width: 9%"
						></Column>
						<Column
							field="note_to_vendor"
							header="Note to Supplier"
							style="width: 15%"
						></Column>
						<Column
							field="need_by_date"
							header="Arrival At Site"
							style="width: 12%"
						>
						</Column>
						<Column
							header="Est. Arrival At Site"
							style="width: 15%"
						>
							<template #body="slotProps">
								<Calendar
									v-model="
										slotProps.data.estimate_supplier_date
									"
									dateFormat="dd-M-yy"
									inputStyle="text-transform: uppercase"
								/>
							</template>
						</Column>
						<Column
							field="reason_not_on_time"
							header="Reason for not on time"
							style="width: 20%"
						>
							<template #body="{ data }">
								<InputText
									v-model="data.reason_not_on_time"
									type="text"
								/>
							</template>
						</Column>
						<Column
							field="name"
							header="Line Status"
							style="width: 9%"
						></Column>
					</DataTable>
				</div>

				<!-- Tombol -->
				<div class="p-fluid grid formgrid">
					<div class="col-6 mb-2 lg:col-1 lg:mb-0">
						<Button
							:class="uppercase"
							class="p-button-secondary"
							label="Back"
							@click="back()"
						>
						</Button>
					</div>
					<div
						v-if="displayConfirm"
						class="col-6 mb-2 lg:col-1 lg:mb-0"
					>
						<Button
							:class="uppercase"
							label="Save"
							@click="save()"
							v-bind:disabled="isSaveClick"
						>
						</Button>
					</div>
					<div
						v-if="displayConfirm"
						class="col-6 mb-2 lg:col-1 lg:mb-0"
					>
						<Button
							:class="uppercase"
							class="p-button-success"
							label="Confirm"
							@click="confirm()"
							v-bind:disabled="isSubmitClick"
						>
						</Button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/* eslint-disable */
import POFulfillment from '../../../service/POFulfillmentService';
import SimpleCrypto from 'simple-crypto-js';
import AuthService from '@/service/AuthService';
import PoFulfillment from '../../../service/PoFulfillment';
import Swal from "sweetalert2"
// import LovService from '../../../service/LovService';
// import { async } from 'q';
import ProgressModal from '../../components/ProgressModal.vue';
// import PoFulfillment from '../../../service/PoFulfillment';
export default {
	name: 'detailPoFulfillmentSup',
	components: { ProgressModal },
	data() {
		return {
			isSupplier: true,
			poKeyId: null,
			dbInstance: null, //this.getCompany(),
			headerId: this.$route.params.id,
			company: sessionStorage.getItem('mailToCompany'),
			poNumber: null,
			confirmDate: null,
			status: null,
			buyer: null,
			revision: null,
			shipTo: null,
			currency: null,
			confirmBy: null,
			assignedBy: null,
			top: null,
			freight: null,
			incoterm: null,
			changeTable: false,
			displayConfirm: true,
			confirmation: null,
			files: null,
			linkFilePur: null,
			linkFileSupp: null,
			filePurIsEmpty: null,
			fileSuppIsEmpty: null,
			fileOnSelectEmpty: true,
			items: [],
			attachments: [],
			dateOld: null,
			confOption: 'manual',
			displayRadioButton: true,
			displayUploadButton: true,
			detailFiles: null,
			orderDate: null,
			idUser: null,
			detailTerms: null,
			recipientMail: null,
			vendorname: null,
			sender: null,
			payloadEmail: null,
			fileLocation: true,
			statusHeader: null,
			emailAttach: {
				filename: null,
				url: null,
			},
			fileUploaded: {
				filename: null,
				url: null,
				mimeType: null,
			},
			breadcrumbHome: { icon: 'pi pi-home', to: '/dashboard' },
			breadcrumbItems: [
				{ label: 'PO Fulfillment', to: '/mgm/settings/sysparamslist' },
				{ label: 'Edit', to: this.$route.path },
			],
			isSaveClick: false,
			isSubmitClick: false,
			confirmStatus: false,
			vendorId: null,
			progress: false,
			newDate: new Date()
		};
	},
	watch:{
		confOption(text){

		},
		confirmDate(t){
			console.log(t, "TERNYATA")
			this.assignedDate = t
		},
	},
	async created() {
		if (this.headerId !== null) {
			const whoResp = await AuthService.whoAmi();
			//
			this.sender = whoResp.data.data.bioemailactive;
			this.idUser = whoResp.data.data.id;
			if (whoResp.status === 200) {
				// const detail = await POFulfillment.getDetail(this.headerId);
				//
				//
				// this.dbInstance = detail.db_instance;
				// this.poNumber = detail.po_number;
				// this.headerId = detail.po_header_id;
				// this.confirmDate = new Date();
				// this.status = detail.status;
				// this.orderDate = detail.order_date;
				// this.buyer = detail.buyer_name;
				// this.revision = detail.revision_num;
				// this.shipTo = detail.ship_to_name;
				// this.currency = detail.currency_code;
				// this.confirmBy = detail.confirm_sp_name;
				// this.top = detail.top;
				// this.freight = detail.freight_terms;
				// this.incoterm = detail.incoterm;
				// Get detail PO Fulfillment
				//
				const payloadDetail = {
					headerId: this.headerId,
					vendorId: sessionStorage.vendorId,
					leveltenant: sessionStorage.leveltenant,
				};

				const responseHeader = await PoFulfillment.getDetailHeader(
					payloadDetail
				);

				if ((await whoResp.data.status) === 202) {
					this.$router.push('/dashboard');
				}
				// this.vendorId = responseHeader.data.data.VENDOR_ID;
				this.statusHeader = responseHeader.data.data.NAME;
				this.dbInstance =
					responseHeader.data.data.DB_INSTANCE &&
					responseHeader.data.data.DB_INSTANCE.toLowerCase() !==
						'null'
						? responseHeader.data.data.DB_INSTANCE
						: '';
				this.poNumber =
					responseHeader.data.data.SEGMENT1 &&
					responseHeader.data.data.SEGMENT1.toLowerCase() !== 'null'
						? responseHeader.data.data.SEGMENT1
						: '';
				this.poKeyId = responseHeader.data.data.PO_KEY_ID;
				this.headerId = responseHeader.data.data.PO_HEADER_ID;
				console.log(`i check date function ${this.checkDate(responseHeader.data.data.CONFIRM_DATE)}`)
				this.confirmDate = this.checkDate(responseHeader.data.data.CONFIRM_DATE)
				this.status = responseHeader.data.data.STATUS || '';
				this.orderDate = responseHeader.data.data.ORDER_DATE;
				this.buyer =
					responseHeader.data.data.BUYER_NAME &&
					responseHeader.data.data.BUYER_NAME !== 'null'
						? responseHeader.data.data.BUYER_NAME
						: '';
				this.revision = responseHeader.data.data.REVISION_NUM;
				this.shipTo =
					responseHeader.data.data.SHIP_TO_NAME &&
					responseHeader.data.data.SHIP_TO_NAME.toLowerCase() !==
						'null'
						? responseHeader.data.data.SHIP_TO_NAME
						: '';
				this.currency =
					responseHeader.data.data.CURRENCY_CODE &&
					responseHeader.data.data.CURRENCY_CODE.toLowerCase() !==
						'null'
						? responseHeader.data.data.CURRENCY_CODE
						: '';
				console.log("whoResp.data.data.fullName", whoResp.data)
				this.confirmBy =  responseHeader.data.data.STATUS === 'O' && responseHeader.data.data.CONFIRM_SP_NAME !== whoResp.data.data.fullname
					? whoResp.data.data.fullname : responseHeader.data.data.CONFIRM_SP_NAME 
				this.assignedBy = responseHeader.data.data.CONFIRM_SP_NAME 
				this.assignedDate = this.checkDate(responseHeader.data.data.CONFIRM_DATE)
				// Get terms detail
				const resTerms = await PoFulfillment.getDetailTerms(
					this.poKeyId
				);
				//
				this.detailTerms = resTerms.data.data[0];

				this.freight =
					resTerms.data.data[0].FREIGHT_TERMS &&
					resTerms.data.data[0].FREIGHT_TERMS.toLowerCase() !== 'null'
						? resTerms.data.data[0].FREIGHT_TERMS
						: '';
				// Make string to capitalize
				this.freight = this.freight.toLowerCase();
				this.freight =
					this.freight.charAt(0).toUpperCase() +
					this.freight.slice(1);
				this.getTop(this.dbInstance);
				this.getIncoterm(this.dbInstance);
				this.items = await POFulfillment.getItems(
					this.poKeyId,
					this.dbInstance
				);

				console.log(this.items, "ITEMS")

				for (var id of this.items) {
					id.need_by_date_ori = id.need_by_date;
					id.need_by_date = new Date(
						id.need_by_date
					).toLocaleDateString('id-ID', {
						year: 'numeric',
						month: 'short',
						day: 'numeric',
					});
					if (
						id.need_by_date == 'Invalid Date' ||
						id.need_by_date == '1 Jan 1970'
					) {
						id.need_by_date = '';
					}
					if (
						id.note_to_vendor === 'null' ||
						id.note_to_vendor === null
					) {
						id.note_to_vendor = '';
					}
					if (
						id.reason_not_on_time == 'null' ||
						id.reason_not_on_time == null
					) {
						id.reason_not_on_time = '';
					}
					id.estimate_supplier_date = new Date(
						id.estimate_supplier_date
					);
					this.dateOld = id.estimate_supplier_date;
					//
					if (
						this.statusHeader === 'NOT CONFIRM' ||
						this.statusHeader === 'NEW'
					) {
						this.displayConfirm = true;
						this.displayRadioButton = true;
						//
					} else {
						this.displayConfirm = false;
						this.displayRadioButton = false;
					}
					//
				}
				this.attachments = await POFulfillment.getFileInfo(
					this.headerId
				);

				//
				//jangan ilangin
				const payload = this.headerId;
				PoFulfillment.getDetailFiles(payload).then((response) => {
					this.detailFiles = response.data.data;
				});
				// Get link download file
				const payloadDownload = {
					poHeaderId: this.headerId,
					revisionNum: this.revision,
					// userreceiveid: this.usersenderid,
				};
				PoFulfillment.downloadFilePur(payloadDownload).then(
					(response) => {
						this.linkFilePur = response.data.data;
					}
				);
				PoFulfillment.downloadFileSupp(payloadDownload).then(
					(response) => {
						this.linkFileSupp = response.data.data;
					}
				);
			}
		}
	},
	computed: {
		// headerStatus() {
		// 	let status;
		// 	const itemStatus =
		// 		this.items && this.items[0] && this.items[0].status;
		// 	const isUseItemStatus =
		// 		this.items &&
		// 		this.items.every((item) => item.status === itemStatus);
		// 	if (isUseItemStatus) {
		// 		status = itemStatus;
		// 	} else {
		// 		status = this.status;
		// 	}
		// 	switch (status) {
		// 		case 'O':
		// 			return 'New';
		// 		case 'N':
		// 			return 'Not Confirmed';
		// 		case 'C':
		// 			return 'Confirmed';
		// 		case 'L':
		// 			return 'Cancelled';
		// 		case 'X':
		// 			return 'Close';
		// 		default:
		// 			return '';
		// 	}
		// },
		attachmentFilePur() {
			let files = this.detailFiles;
			let file = null;
			if (files === null) {
				this.filePurIsEmpty = true;
			} else {
				for (const element of files) {
					if (
						element.PO_HEADER_ID == this.headerId &&
						element.LEVEL_TENANT == 1 &&
						element.PO_SEQ == this.revision
					) {
						file = element;
					}
				}
				if (file === null) {
					this.filePurIsEmpty = true;
				} else {
					this.filePurIsEmpty = false;
					this.filePur = file;
				}
			}

			return file;
		},
		attachmentFileSupp() {
			let files = this.detailFiles;
			let file = null;
			if (files == null) {
				this.fileSuppIsEmpty = true;
			} else {
				for (const element of files) {
					if (
						element.PO_HEADER_ID == this.headerId &&
						element.LEVEL_TENANT == 2 &&
						element.PO_SEQ == this.revision
					) {
						file = element;
					}
				}
				if (file === null) {
					this.fileSuppIsEmpty = true;
				} else {
					this.fileSuppIsEmpty = false;
					this.fileSupp = file;
				}
			}

			return file;
		},
	},
	methods: {
		formatCurrency(value) {
			if (value) return value.toLocaleString('id-ID');
			return;
		},
		async handleEmailAttachment() {
			const payloadDownload = {
				poHeaderId: this.headerId,
				revisionNum: this.revision,
				// userreceiveid: this.usersenderid,
			};
			const response = await PoFulfillment.downloadFileSupp(
				payloadDownload
			);
			if (response.status === 200) {
				this.emailAttach.url = response.data.data;

				// await new Promise((resolve) => setTimeout(resolve, 700));
				return response;
			}

			//
		},
		async getTop(dbInstance) {
			const payload = { isi: dbInstance };
			const res = await PoFulfillment.getTop(payload);
			this.isiTop = res.data.data;
			this.top =
				(this.detailTerms &&
					this.isiTop.find(
						(val) => val.term_id === this.detailTerms.TERMS_ID
					)?.name) ||
				'';
		},
		async getIncoterm(dbInstance) {
			const payload = { dbInstance };
			const res = await PoFulfillment.getIncoterm(payload);

			this.isiIncoterm = res.data.data;
			this.incoterm =
				(this.detailTerms &&
					this.isiIncoterm.find(
						(val) => val.value === this.detailTerms.FOB
					)?.name) ||
				'';
		},
		async sendNotifToPur() {
			// DAPETIN EMAIL RECIPIENT/COMPANY
			const data = {
				module: 'PoFulfillToPur',
				idUser: this.idUser,
				companyname: this.company,
				sendpodata: this.payloadEmail,
			};
			const respond = await POFulfillment.getRecipientMail(data);
			if (respond.status === 200) {
				this.dbInstance = respond.data.data[0][0]['DB_INSTANCE'];
				var tampungEmail = [];
				// var getOrgId = null;
				var suppliername = null;
				respond.data.data[0].forEach((element) => {
					tampungEmail.push(element.targetemailgroup);
					// if (getOrgId === null) {
					// 	getOrgId = element.id;
					suppliername = element.vendorname;
					// }
				});
				this.recipientMail = tampungEmail
					.toString()
					.replace(/"/g, '')
					.replace(/[\[\]']+/g, '')
					.replace(/ /g, '')
					.replace(/,/g, ', ');
				this.vendorname = suppliername;
				// this.orgIdNotif = getOrgId;
				//

				// KIRIM NOTIFIKASI KE DASHBOARD
				const payload = {
					idmodule: '4',
					sbjmessage:
						'Purchase Order #' +
						this.poNumber +
						' has been confirmed by ' +
						this.vendorname,
					// receiveddate: new Date(),
					status: 'Close',
					notif_type: 'Purchasing',
					sender: this.sender,
					recipient: this.recipientMail,
					dbInstance: this.dbInstance,
					orgIdComp: this.items[0].org_id,
					usersenderid: this.idUser,
					// userreceiveid: this.usersenderid,
				};

				const res = await POFulfillment.sendPONotifSupp(payload);
				if (res.status === 200) {
					this.recipientMail = [];
					window.alert('Purchasing Successfully Notified');
					return res;
				}
			}
		},
		async getEmail() {
			const data = {
				module: 'PoFulfillToPur',
				idUser: this.idUser,
				companyname: this.company,
				sendpodata: null,
			};
			const respond = await POFulfillment.getRecipientMail(data);
			if (respond.status === 200) {
				this.dbInstance = respond.data.data[0][0]['DB_INSTANCE'];
				var tampungEmail = [];
				// var getOrgId = null;
				var suppliername = null;
				respond.data.data[0].forEach((element) => {
					tampungEmail.push(element.targetemailgroup);
					// if (getOrgId === null) {
					// 	getOrgId = element.id;
					suppliername = element.vendorname;
					// }
				});
				this.recipientMail = tampungEmail
					.toString()
					.replace(/"/g, '')
					.replace(/[\[\]']+/g, '')
					.replace(/ /g, '')
					.replace(/,/g, ', ');
				this.vendorname = suppliername;
				// this.orgIdNotif = getOrgId;
				//

				return respond;
			}
		},
		changeDisplay(value) {
			if (value == true) {
				this.displayUploadButton = true;
				this.confOption = 'manual';
			} else {
				this.displayUploadButton = false;
				this.confOption = 'digital';
			}
		},
		formatDate(value) {
			const d = Date.parse(value);
			return new Date(d).toLocaleDateString('id-ID', {
				day: '2-digit',
				month: 'short',
				year: 'numeric',
			});
		},
		formatDateSend(date) {
			var d = new Date(date),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear();
			if (month.length < 2) month = '0' + month;
			if (day.length < 2) day = '0' + day;
			return [year, month, day].join('-');
		},
		formatDateDexa(date) {
			var date = new Date(date);
			var respondDate =
				('00' + (date.getMonth() + 1)).slice(-2) +
				'/' +
				('00' + date.getDate()).slice(-2) +
				'/' +
				date.getFullYear() +
				' ' +
				('00' + date.getHours()).slice(-2) +
				':' +
				('00' + date.getMinutes()).slice(-2) +
				':' +
				('00' + date.getSeconds()).slice(-2);
			return respondDate;
		},
		formatDateLoe(inputDate) {
			let date, month, year;

			date = inputDate.getDate();
			month = inputDate.getMonth() + 1;
			year = inputDate.getFullYear();

			date = date.toString().padStart(2, '0');

			month = month.toString().padStart(2, '0');

			return `${month}/${date}/${year}`;
		},
		getCompany() {
			const secretKey = 'some-unique-key';
			const simpleCrypto = new SimpleCrypto(secretKey);
			return simpleCrypto.decrypt(
				sessionStorage.getItem('dropdownCompany')
			);
		},
		previewFiles(event) {
			this.files = 1;
		},
		async onRemoveSupp(payload) {
			const payloadRemove = {
				poHeaderId: payload,
				revisionNum: this.revision,
			};

			const res = await PoFulfillment.removeFileSupp(payloadRemove);
			if (res.status === 200) {
				this.fileSuppIsEmpty = true;
				this.$toast.add({
					severity: 'success',
					summary: 'File has been Removed !',
					life: 3000,
				});
				this.file = null;
			}
		},
		async onRemoveSuppSubmit(payload) {
			const payloadRemove = {
				poHeaderId: payload,
				revisionNum: this.revision,
			};

			const res = await PoFulfillment.removeFileSupp(payloadRemove);
			if (res.status === 200) {
				this.fileSuppIsEmpty = true;
				this.file = null;
			}
		},
		async onUpload() {
			//this.loadingUpload = true;
			// let file = this.file
			// let fileUploaded = this.fileUploaded
			// let userId = sessionStorage.getItem('idUser');
			const password = sessionStorage.getItem('vendorId');
			//
			//
			// const password = sessionStorage.getItem('vendorId');
			let formData = new FormData();
			formData.append('file', this.file);
			formData.append('poHeaderId', this.headerId);
			formData.append('userId', sessionStorage.getItem('idUser'));
			formData.append('revisionNum', this.revision);
			formData.append('password', password);

			// Uploading file
			this.loadingUpload = true;
			let res = null;

			if (this.fileSuppIsEmpty === true) {
				res = await PoFulfillment.uploadFileSupp(formData);
			} else {
				res = await PoFulfillment.uploadFileUpdateSupp(formData);
			}

			if (res.status === 200) {
				this.fileUploaded = {
					filename: res.data.fileName,
					url: res.data.data.name,
					mimeType: res.data.mimeType,
				};
				// this.$refs.inputFile.clear();
				this.loadingUpload = false;

				// this.attachmentFilePur();
				return res;
			} else if (res.data.status === 204) {
				this.isSubmitClick = false;
				this.progress = false;
				this.$toast.add({
					severity: 'error',
					summary: 'PDF already has password !',
					life: 3000,
				});
			} else {
				this.isSubmitClick = false;
				this.progress = false;
				this.$toast.add({
					severity: 'error',
					summary: 'File Failed to Upload !',
					life: 3000,
				});
			}
		},
		onSelect(e) {
			this.fileUploaded = null;
			this.file = e.files[0];
			this.emailAttach.filename = this.file.name;

			this.fileLocation = true;
			this.fileOnSelectEmpty = false;
			//
		},
		back() {
			this.$router.push({
				name: 'poFulfillment',
			});
		},
		save() {
			if (!this.isSaveClick) {
				this.progress = true;
				this.isSaveClick = true;
				AuthService.whoAmi().then(async (response) => {
					var datesave = this.formatDateDexa(new Date());
					var id = response.data.data.id;
					var name = response.data.data.userid;
					if (this.confOption === 'manual') {
						var header_updatesave = {
							username: name,
							userid: id,
							flag: 3,
							header: this.headerId,
							confirmDate: this.formatDateDexa(this.assignedDate),
							date: datesave,
							confirm_opt: 'ND',
							statusHeader: 'N',
						};
					} else {
						header_updatesave = {
							username: name,
							userid: id,
							flag: 1,
							confirm_by: this.confirmBy,
							header: this.headerId,
							date: datesave,
							confirmDate: this.formatDateDexa(this.assignedDate),
							confirm_opt: 'D',
							statusHeader: 'N',
						};
					}

					// Extend payload detail items
					const placepayloadsave = await Promise.all(
						this.items.map(async (item) => {
							// Get LOE

							const resLoe = await PoFulfillment.getLoe({
								statDay: item.stat_day,
								arrival: this.formatDateLoe(
									new Date(item.need_by_date_ori)
								),
								estArrival: this.formatDateLoe(
									new Date(item.estimate_supplier_date)
								),
							});
							item.ot_code = resLoe.data.data.codeLoe;

							// Save by
							item.userid = response.data.data.id;

							// Last update date
							const formattedDate = this.formatDateDexa(
								new Date()
							);

							// Est and need by date
							const formattedEstimateDate = this.formatDateDexa(
								new Date(item.estimate_supplier_date)
							);
							const formattedNeedByDate = this.formatDateDexa(
								new Date(item.need_by_date_ori)
							);

							// Remarks
							if (
								item.remarks == 'null' ||
								item.remarks == null
							) {
								item.remarks = '';
							}

							item.statusvalue = 'O';

							item.name = 'OPEN';
							return {
								...item,
								estimate_supplier_date: formattedEstimateDate,
								need_by_date: formattedNeedByDate,
								date: formattedDate,
							};
						})
					);

					let payloadkirimsave = {
						detail: placepayloadsave,
						header: header_updatesave,
					};

					let data = await PoFulfillment.savePo(payloadkirimsave);

					if(data.data.status === 200){
						this.$toast.add({
							severity: 'success',
							summary: 'Update PO',
							detail: 'Success to update PO',
							life: 3000,
						});
					}else{
						this.$toast.add({
							severity: 'error',
							summary: 'Update PO',
							detail: 'Error Failed to update PO',
							life: 3000,
						});
					}
					// .then((response) => {
					//
					// 	if (response.data.status == 200) {
					// 		location.reload();
					// 	}
					// })
					// .catch((err) =>
					//
					// );

					for (var id of this.items) {
						id.estimate_supplier_date = new Date(
							id.estimate_supplier_date
						);
						id.need_by_date = new Date(
							id.need_by_date
						).toLocaleDateString('id-ID', {
							year: 'numeric',
							month: 'short',
							day: 'numeric',
						});
					}

					this.displayConfirm = true;
					this.displayRadioButton = true;
					if (this.file == null) {
						this.isSaveClick = false;
						this.progress = false;
					} else {
						if (this.confOption === 'manual') {
							let upload = this.onUpload();
							upload.then((respond) => {
								if (respond.status === 200) {
									this.progress = false;
									this.$toast.add({
										severity: 'success',
										summary: 'Status Change',
										detail: 'Change Status to Not Confirm',
										life: 3000,
									});
									location.reload();
								}
							});
						} else {
							this.progress = false;
							this.$toast.add({
								severity: 'success',
								summary: 'Status Change',
								detail: 'Change Status to Not Confirm',
								life: 3000,
							});
						}
						this.isSaveClick = false;
					}
				});
			}

			//
		},
		async submitDataSuppProcess(payloadkirim) {
			if (this.file == null) {
				const response = await POFulfillment.submitDataSupp(
					payloadkirim
				);
				if (response.status === 200) {
					console.log('file kosong,', response)
					for (var id of this.items) {
						id.estimate_supplier_date = new Date(
							id.estimate_supplier_date
						);
						id.need_by_date = new Date(id.need_by_date_ori);
					}
					this.$toast.add({
						severity: 'success',
						summary: 'Status Change',
						detail: 'Confirm PO Success',
						life: 3000,
					});
					this.progress = false;
					location.reload()
					// 	this.$router.push({
					// 		name: 'poFulfillment',
					// 	});
				} 
				// else {
				// 	this.progress = false;
				// 	this.$toast.add({
				// 		severity: 'error',
				// 		summary: 'File Already Exist',
				// 		detail: 'File Already exist',
				// 		life: 3000,
				// });
				// }
			} else {
				const upload = await this.onUpload();
				if (upload.status === 200) {
					const response = await POFulfillment.submitDataSupp(
						payloadkirim
					);
					console.log('rrespnose', response);
					if (response.data.status === 200) {
						for (var id of this.items) {
							id.estimate_supplier_date = new Date(
								id.estimate_supplier_date
							);
							id.need_by_date = new Date(id.need_by_date_ori);
						}
						this.$toast.add({
							severity: 'success',
							summary: 'Status Change',
							detail: 'Confirm PO Success',
							life: 3000,
						});

						this.progress = false;
						location.reload()
						// 	this.$router.push({
						// 		name: 'poFulfillment',
						// 	});
					} else if (response.data.status === 500) {
						this.isSubmitClick = false;
						const payload = this.headerId;
						this.onRemoveSuppSubmit(payload);
						this.$toast.add({
							severity: 'error',
							summary: 'Send Email Error',
							detail: 'Unable to Send Email',
							life: 3000,
						});
						this.progress = false;
					} else {
						this.isSubmitClick = false;
						const payload = this.headerId;
						this.onRemoveSupp(payload);
						this.$toast.add({
							severity: 'error',
							summary: 'Error Data',
							detail: "There's Something Wrong",
							life: 3000,
						});
						this.progress = false;
					}
				}
			}
		},
		async confirm() {
			this.progress = true;
			if (!this.isSubmitClick) {
				this.isSubmitClick = true;
				AuthService.whoAmi().then(async (response) => {
					//
					var username = response.data.data.fullname;
					var user_id = response.data.data.id;
					var user_name = response.data.data.userid;
					if (this.confOption == 'digital') {
						// if (this.files == 1) {
						var datedigi = this.formatDateDexa(new Date());
						var header_updatedigi = {
							username: user_name,
							userid: user_id,
							confirm_opt: 'D',
							flag: 1,
							header: this.headerId,
							statusHeader: 'C',
							confirmDate: this.formatDateDexa(this.assignedDate),
							confirm_by: this.confirmBy
						};
						var add_detaildigi = {
							status: 'C',
							date: datedigi,
							confirmDate: this.formatDateDexa(this.assignedDate),
						};

						// Extend payload detail items
						const placepayloaddigi = await Promise.all(
							this.items.map(async (item) => {
								// Get LOE
								const resLoe = await PoFulfillment.getLoe({
									statDay: item.stat_day,
									arrival: this.formatDateLoe(
										new Date(item.need_by_date_ori)
									),
									estArrival: this.formatDateLoe(
										new Date(item.estimate_supplier_date)
									),
								});
								item.ot_code = resLoe.data.data.codeLoe;

								// Est and need by date
								item.estimate_supplier_date =
									this.formatDateDexa(
										new Date(item.estimate_supplier_date)
									);
								item.need_by_date = this.formatDateDexa(
									new Date(item.need_by_date_ori)
								);

								// Remarks
								if (
									item.remarks == 'null' ||
									item.remarks == null
								) {
									item.remarks = '';
								}

								// Reason not on time
								if (
									item.reason_not_on_time === 'null' ||
									item.reason_not_on_time === null
								) {
									item.reason_not_on_time = '';
								}

								return {
									...item,
									...add_detaildigi,
									...header_updatedigi,
								};
							})
						);

						let payloadkirimdigi = {
							detail: placepayloaddigi,
							header: header_updatedigi,
						};

						const response = await this.getEmail();
						if (response.status === 200) {
							let payloadSendApi = {
								db_instance: this.dbInstance,
								po_header_id: this.headerId,
								po_number: this.poNumber + ',' + this.revision,
								confirm_digital: 'Y',
								email_name: this.recipientMail,
								po_key_id: this.poKeyId,
								confirm_date: this.formatDateDexa(
									this.assignedDate
								),
								username: this.confirmBy,
							};

							const payload = {
								payloadapi: payloadSendApi,
								payloadsubmit: payloadkirimdigi,
							};

							const resSubmitApi = await POFulfillment.submitApi(
								payload
							);

							if (resSubmitApi.status === 200) {
								for (var id of this.items) {
									id.estimate_supplier_date = new Date(
										id.estimate_supplier_date
									);
									id.need_by_date = new Date(
										id.need_by_date_ori
									);
								}
								// if (resSubmitApi.status === 200) {
								this.$toast.add({
									severity: 'success',
									summary: 'Status Change',
									detail: 'Change Status to Confirmed',
									life: 3000,
								});
								this.progress = false;
								this.isSubmitClick = false;
								window.alert('Data Send to Dexa API');
								location.reload();
							} else {
								this.isSubmitClick = false;
								this.progress = false;
								window.alert('Data Submit Error');
							}
							this.isSubmitClick = false;
						}

						//sini
					} else if (this.confOption == 'manual') {
						if (
							this.fileSuppIsEmpty === true &&
							this.fileOnSelectEmpty === true
						) {
							Swal.fire(
								'Confirm Failed!',
								'Silahkan upload file terlebih dahulu',
								'error'
							)
							this.isSubmitClick = false;
							this.progress = false;
							return;
						}
						// else if (this.fileOnSelectEmpty === true){
						// 	window.alert('File already exist');
						// 	this.isSubmitClick = false;
						// 	this.progress = false
						// }
						else {
							var date = this.formatDateDexa(new Date());
							var header_update = {
								username: user_name,
								userid: user_id,
								confirm_opt: 'ND',
								flag: 3,
								header: this.headerId,
								statusHeader: 'C',
								confirmDate: this.formatDateDexa(
									this.assignedDate
								),
								confirm_by: this.assignedBy
							};
							var add_detail = {
								status: 'O',
								date: date,
								confirmDate: this.formatDateDexa(
									this.assignedDate
								),
							};

							// Extend payload detail items
							const placepayload = await Promise.all(
								this.items.map(async (item) => {
									// Get LOE
									//
									//
									const resLoe = await PoFulfillment.getLoe({
										statDay: item.stat_day,
										arrival: this.formatDateLoe(
											new Date(item.need_by_date_ori)
										),
										estArrival: this.formatDateLoe(
											new Date(
												item.estimate_supplier_date
											)
										),
									});
									item.ot_code = resLoe.data.data.codeLoe;

									// Est and need by date
									item.estimate_supplier_date =
										this.formatDateDexa(
											new Date(
												item.estimate_supplier_date
											)
										);
									item.need_by_date = this.formatDateDexa(
										new Date(item.need_by_date_ori)
									);

									// Remarks
									if (
										item.remarks == 'null' ||
										item.remarks == null
									) {
										item.remarks = '';
									}

									// Reason not on time
									if (
										item.reason_not_on_time === 'null' ||
										item.reason_not_on_time === null
									) {
										item.reason_not_on_time = '';
									}

									item.name = 'OPEN';

									return {
										...item,
										...add_detail,
										...header_update,
									};
								})
							);

							// let payloadkirim = {
							// 	detail: placepayload,
							// 	header: header_update,
							// };

							//

							// POFulfillment.submitDataSupp(payloadkirim).then(
							// 	(response) => {
							//
							// 			'Confirm manual response',
							// 			response
							// 		);
							// 	}
							// );
							// for (var id of this.items) {
							// 	id.estimate_supplier_date = new Date(
							// 		id.estimate_supplier_date
							// 	);
							// 	id.need_by_date = new Date(id.need_by_date);
							// }
							// this.$toast.add({
							// 	severity: 'success',
							// 	summary: 'Status Change',
							// 	detail: 'Change Status to Processed',
							// 	life: 3000,
							// });
							let payloadkirimemail = {
								detail: placepayload,
								header: header_update,
								buyer: this.buyer,
								order_date: this.orderDate,
								confirm_date: this.assignedDate,
								po_number: this.poNumber,
								currency: this.currency,
								ship_to: this.shipTo,
								rev: this.revision,
								emails: this.recipientMail,
								sender: this.sender,
								vendorname: this.vendorname,
								filename_supp: null,
								file_url_supp: null,
							};
							let payloadkirim = {
								detail: placepayload,
								header: header_update,
								module: 'PoFulfillToPur',
								idUser: this.idUser,
								companyname: this.company,
								sendpodata: payloadkirimemail,
								idmodule: '4',
								sbjmessage:
									'Purchase Order #' +
									this.poNumber +
									' has been confirmed by ',
								// receiveddate: new Date(),
								recipient: null,
								sender: this.sender,
								status: 'Close',
								notif_type: 'Purchasing',
								dbInstance: this.dbInstance,
								orgIdComp: this.items[0].org_id,
								usersenderid: this.idUser,
								vendorId: sessionStorage.vendorId,
							};
							if (this.file == null) {
								// this.payloadEmail = payloadkirimemail;

								payloadkirimemail.filename_supp =
									this.attachmentFileSupp.FILENAME;
								payloadkirimemail.file_url_supp =
									this.linkFileSupp;
								// const res = await this.sendNotifToPur();
								await this.submitDataSuppProcess(payloadkirim);
								// if (res.status === 200) {
								// 	this.$router.push({
								// 		name: 'poFulfillment',
								// 	});
								// }
							} else {
								// await this.onUpload();
								// await this.handleEmailAttachment();
								// if (response === 200) {

								payloadkirimemail.filename_supp =
									this.emailAttach.filename;
								// payloadkirimemail.file_url_supp = this.fileUploaded.url
								// this.payloadEmail = payloadkirimemail;

								// const res = await this.sendNotifToPur();
								await this.submitDataSuppProcess(payloadkirim);
								// if (res.status === 200) {
								// 	this.$router.push({
								// 		name: 'poFulfillment',
								// 	});
								// }
							}
						}
						//
					}
				});
			}
		},
		checkDate(date){
			console.log(`we check the date`)
			const dateModified = date
			console.log(`we check the date`)
			if(dateModified) {
				console.log(dateModified, "HAVING DATE")
				return new Date(dateModified)
			}

			console.log("Didn't have date")
			return new Date()
		}
	},
};
</script>
<style scoped>
a.p-button-success {
	text-decoration: none;
	color: #ffffff;
	background: #22c55e;
	border: 1px solid #22c55e;
	/* padding: 0.75rem 1.25rem;
	font-size: 1rem; */
	transition: background-color 0.2s, color 0.2s, border-color 0.2s,
		box-shadow 0.2s;
	border-radius: 6px;
	display: inline-flex;
	align-items: center;
	vertical-align: bottom;
	text-align: center;
	overflow: hidden;
	position: relative;
	font-size: 0.875rem;
	padding: 0.65625rem 1.09375rem;
}
</style>
